

export const COLUMNS = [
  {
    Header: 'Sr.No',
    accessor: 'id',
    disableFilters: true,

  },
  {
    Header: 'Event Name',
    accessor: 'Event Name',
  },
  {
    Header: 'Batch Size',
    accessor: 'Targt & Batch Size',
  },
  {
    Header: 'Date',
    accessor: 'Date',

  },
  
 
  
 
]

