import React from "react";

const sponser = () => {
  return (
    <div >
      sponser
    </div>
  );
};

export default sponser;
