import React from 'react'
import "./past_event.css";
import Temp from "./temp/temp"


const past_event = () => {
  return (
    <div className='past_event'>
      <Temp/>
    </div>
  )
}

export default past_event