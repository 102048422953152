

export const COLUMNS = [
  {
    Header: 'Sr.No',
    accessor: 'id',
    disableFilters: true,

  },
  {
    Header: 'Description',
    accessor: 'Description',
  },
  {
    Header: 'Quantity',
    accessor: 'Quantity',

  },
 
  
 
]